.estimatePage{
    background-color: white;
    color: black;
    max-width: 100vw !important;
    max-height: 100vh !important;
    /* overflow: scroll; */
}

.estimateTableCell{
    padding:0px !important;
}

.estimateTableCellVariant{
    padding:8px !important
}
