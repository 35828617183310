.BadgeIcon{
    text-align: left !important;
    width: 100% !important;
}

.AvatarIcon{
    background-color: deepskyblue !important;
    border: solid #299fff 2px
}

.AvatarIcon:hover{
    background-color:#299fff !important;
    border: solid deepskyblue 2px
}

.tabPanel{
    margin-top: 64px;
}

.sideMenu{
    width: 256px;
    top: 64px;
    margin-top: 64px;
    border: none;

}

.Wrapper{
    justify-self: end;
    width: 85%;
    height: 93vh;
}

.mobileTabButton{
    font-weight:500 !important;
    color: white !important;
}

.MobileMenu{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    height: fit-content;
}

.MobileMenuOptions{
    display: flex;
    flex-direction: row;
}

.MobileMenuDisplay{
    display: flex;
    flex-direction: row;
}

@media (max-width:900px){
    .tabParent{
        display: none !important;
    }
    
    .tabChild{
        display: none !important;
    }

    .logo{

    }

    .logoImage{
        width: 100%;
    }

    .mobileTabs{

    }

    .tabPanel{
        margin-top: 56px;
    }

    .sideMenu{
        width:200px !important;
        margin-top: 0px !important;
        height: 86% !important;
    }

}

.mobileTabs{
    justify-content: flex-end;
}

.logo{
 width: 15vw;
}

.tabChild{
    font-weight: bold;
    color:rgba(223,223,223) !important;
}

.tabParent{
    width:85vw;
    height: 100%;
    justify-items: flex-end;
}

.tablist{
    justify-content: flex-end;
}