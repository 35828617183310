.pdfModal{
    margin: auto;
    padding: auto;
    text-align: center;
    background-color: white;
    color: white;
}

.pdfNotice{
    text-decoration:underline;
    font-style: italic;
}

.pdfCheck{
}

.invoiceModal{
    margin: auto;
    padding: auto;
    text-align: center;
    background-color: white;
    color: white;
    overflow: scroll;
}

.estimateModal{
    margin: auto;
    padding: auto;
    text-align: center;
    background-color: white !important;
    overflow: scroll;
}
.estimateDiv{
    background-color: white !important;
    width: 100vw;
    height: 100vh;
}

.delBtn:hover{
    background-color: darkred !important;
}