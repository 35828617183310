.employeeStatusModal{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.statusBox{
    background-color: white;
    padding: 10px;
}