.registerForm {
    /* background-color: #5e5e5e5b!important;
    border: 2px solid #545b59; */
    width: 50%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    padding: 3%;
    }

.registerText {
    color: black !important;
    padding: .75rem;
    margin-left: 1rem;
}

.registerHeaderText {
    font-size:1.5rem;
    font-weight: 600;
    text-align: center;
}

.registerHeader {
    margin-top: 1rem;
}

.registerBtn {
    width: 40%;
    margin: auto;
    margin-top: 1rem;
    background-color:#1976d2 !important;
    color: white !important;
}

.forgot{
    margin-top: 3rem;
    text-align: center;
    text-decoration: underline;
}

.forgot:hover{
    color: #80b6d1 !important;
}

.homeTutorialVideo{
    width: 50%;
}

.aboutDiv{
    flex-direction: row;
}

.publicCard{
    border: '1px solid black';
    margin: 'auto';
    height: 50vh !important;
    width: 35%;
    margin-top: 20px ;
    margin: auto;
}

@media (max-width:900px){
    /* .homeTutorialVideo{
        width: 95%;
    } */

    .aboutDiv{
        flex-direction: column;
    }   

    .publicCard{
        width:75%;
        /* height: 1000px; */
    }
    
    .youtubeDiv{
        width:90% !important
    }

    .vidAccordion{

    }
}

@media (max-width:500px){
    .aboutDiv{
        flex-direction: column;
    }   

    .publicCard{
        width:75%;
    }
    
    .youtubeDiv{
        width:90% !important
    }

    .youtubeCard{
        width:95% !important
    }
}