.processorList{
    display: flex;
    flex-wrap: wrap;
    
}

.processorIndex{
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #FAFAFA;
    border-radius: 15px;
    margin: 12px;
    padding: 8px;
    width: 12em;
}

.messageModal{
    height: 25%;
    width: 35%;
}

.messageForm{
    display: flex;
    flex-direction: column;
}

.messageBtn{
    margin-top:5%;
}